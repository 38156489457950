@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200|Material+Icons+Round|Material+Icons+Two+Tone|Material+Icons+Sharp");
@import url("https://fonts.googleapis.com/css?family=Lato:wght@300;400;500;600;700;800;900&display=swap");

@import "bootstrap";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  font-family: Lato, sans-serif;
  padding-top: 58px;
}

.Main {
  display: flex;
  flex-direction: column;
  min-height: 94vh;

  .Footer {
    flex: 0 0 auto;
    margin-bottom: -2px;
  }

  .MainContent,
  .Login,
  .ForgotPassword {
    flex: 1 0 auto;
  }

  .MainHeader {
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) {
  .scroller {
    height: 94vh;
    position: sticky;
    top: 6vh;
    overflow: auto;
  }
}

.material-icons,
.material-icons-outlined,
.material-symbols-outlined {
  vertical-align: text-bottom;

  &.md-12 {
    font-size: 0.75rem;
  }

  &.md-14 {
    font-size: 0.875rem;
  }

  &.md-16 {
    font-size: 1rem;
  }

  &.md-18 {
    font-size: 1.125rem;
  }

  &.md-20 {
    font-size: 1.25rem;
  }

  &.md-24 {
    font-size: 1.5rem;
  }

  &.md-32 {
    font-size: 2rem;
  }

  &.md-36 {
    font-size: 2.25rem;
  }

  &.md-48 {
    font-size: 3rem;
  }

  &.md-64 {
    font-size: 4rem;
  }

  &.md-72 {
    font-size: 4.5rem;
  }

  &.md-84 {
    font-size: 5.25rem;
  }

  &.md-96 {
    font-size: 6rem;
  }

  &.md-120 {
    font-size: 7.5rem;
  }
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
}

.modal.left .modal-header,
.modal.right .modal-header,
.modal.left .modal-body,
.modal.right .modal-body,
.modal.right .modal-footer,
.modal.left .modal-footer {
  padding: 32px 32px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: 0px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0px;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0px;
}

/* ----- MODAL STYLE ----- */
.modal.left .modal-content,
.modal.right .modal-content {
  border-radius: 0;
  border: none;
}

@media (min-width: 768px) {

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 480px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    left: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

@media (min-width: 992px) {

  .modal.left .modal-dialog,
  .modal.right .modal-dialog {
    width: 640px;
  }

  /*Left*/
  .modal.left.fade .modal-dialog {
    width: 0px;
  }

  /*Right*/
  .modal.right.fade .modal-dialog {
    right: 0px;
  }
}

.dropdown {
  .dropdown-toggle::after {
    font-family: "Material Icons";
    font-size: 24px;
    color: inherit;
    vertical-align: middle;
    line-height: 1;
    margin: -2px 0 0 0;
    content: "\e5cf";
    border: none;
  }

  .dropdown-toggle.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.navbar-nav {
  .nav-item.dropdown.bg-opacity-10:hover {
    @extend .bg-opacity-25;
  }
}

.btn:focus {
  box-shadow: none;
}

.breadcrumb-item {
  a {
    text-decoration: none;
  }
}

// consider removing
.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.accordion-body {
  max-height: 25rem;
  overflow: auto;
}

.btn.btn-outline-primary:hover a:hover {
  color: #fff;
}

.dropdown-menu {
  font-size: 1rem;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  padding: 0.5rem 0;
  list-style: none;
  text-align: left;
  color: #525f7f;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dropdown-menu .dropdown-item {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.dropdown-item {
  font-weight: 400;
  display: block;
  clear: both;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: inherit;
  white-space: nowrap;
  color: #212529;
  border: 0;
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 1rem;
  height: 1rem;
  padding: 0.4rem;
  font-size: 0.6rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
  --bs-bg-opacity: 0.5;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 250, "GRAD" 0, "opsz" 48;
}

.card-header {
  background-color: rgb(242 250 255);
  border-bottom: var(--bs-card-border-width) solid rgb(229 229 229 / 58%);
}

.modal {
  background: rgba(255, 255, 255, 0.44);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  display: block;
}

.btn-light,
.dropdown-item:hover,
.dropdown-item:focus {
  @extend .bg-primary;
  @extend .text-primary;
  @extend .bg-opacity-10;
}

.btn-light:hover {
  border-color: lighten($primary, 60%);
}

.modal-header .btn-close {
  background-color: $gray-200;
  @extend .rounded-circle;

  &:hover {
    @extend .btn-secondary;
  }
}

.form-control:focus,
.form-select:focus,
textarea:focus {
  @extend .border-primary;
  box-shadow: 0 0rem 0.5rem #e6f3fc;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: 0 0.087rem 0.18rem #0079c240;
}

.btn-outline-primary.border {
  --bs-btn-border-color: #0079c2;
  --bs-btn-hover-color: #0079c2;
  --bs-btn-hover-bg: hsl(203deg 100% 38% / 10%);
  --bs-btn-hover-border-color: #0079c2;
}

.btn-secondary {
  @extend .bg-opacity-10;
  @extend .bg-secondary;
  @extend .border;
  @extend .btn-secondary;
  @extend .text-dark;
}

.sticky-header {
  z-index: 5;
  top: 3.5rem;
}

/* scrollbar */

::-webkit-scrollbar {
  background: transparent;
  height: 8px;

  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  margin: 3px 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: none;
  box-shadow: none;
  background: #b7b7b9;
  border-radius: 4px;
  min-height: 40px;
  height: 50px;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8c8888;
}

.table {
  thead th {
    padding-top: $table-head-spacer-y;
    padding-bottom: $table-head-spacer-y;
    font-size: $table-head-font-size;
    text-transform: $table-head-text-transform;
    letter-spacing: $table-head-letter-spacing;
    border-bottom: $table-border-width solid $table-border-color;
  }

  th {
    font-weight: $table-head-font-weight;
  }

  td {
    .progress {
      height: 3px;
      width: 120px;
      margin: 0;
    }
  }

  td,
  th {
    font-size: $table-body-font-size;
    white-space: normal;
    max-width: 12rem;
  }

  // Vetical align table content

  &.align-items-center {

    td,
    th {
      vertical-align: middle;
    }
  }

  // Styles for dark table

  .thead-dark {
    th {
      background-color: $table-dark-head-bg;
      color: $table-dark-head-color;

      a {
        color: $table-dark-head-color;
      }
    }
  }

  // Styles for light table

  .thead-light {
    th {
      background-color: $table-head-bg;
      color: $table-head-color;

      a {
        color: $table-head-color;
      }
    }
  }
}

// Add transition for hover state

.table-hover {
  tr {
    @include transition($transition-base);
  }
}

// Flush tables

.table-flush {

  td,
  th {
    border-left: 0;
    border-right: 0;
  }

  tbody {
    tr {
      &:first-child {

        td,
        th {
          border-top: 0;
        }
      }

      &:last-child {

        td,
        th {
          border-bottom: 0;
        }
      }
    }
  }
}

// Tables inside cards

.card {
  .table {
    margin-bottom: 0;

    td,
    th {
      padding-left: $card-spacer-x;
      padding-right: $card-spacer-x;
    }
  }
}

// Fixes for custom elements inside tables

.table {
  .custom-toggle {
    display: block;
  }
}

.custom-pill .nav-link {
  background: none;
  border: 0;
  border-radius: 5rem;
}

.custom-pill .nav-link {
  background: none;
  border: 0;
  border-radius: 5rem;
}

.placeholder {
  background-color: #a7b9c3;
}

.toast:not(.camera-toast) {
  bottom: -5rem;
  opacity: 0;
  transform: scale(0.5);
  -webkit-transition: transform 500ms, bottom 400ms, opacity 450ms;
  -moz-transition: transform 500ms, bottom 400ms, opacity 450ms;
  -o-transition: transform 500ms, bottom 400ms, opacity 450ms;
  -ms-transition: transform 500ms, bottom 400ms, opacity 450ms;
  transition: transform 500ms, bottom 400ms, opacity 450ms;
}

.toast.active {
  bottom: -1rem;
  transform: scale(1);
  opacity: 1;
}

sub {
  bottom: 0.2em;
}

span sub {
  bottom: 0.1em;
}

.form-control {
  padding: 0.5rem 0.75rem;
}

.react-multi-email [data-tag] {
  background-color: hsl(203 100% 98% / 1) !important;
  color: #0079c2 !important;
  font-weight: 500 !important;
  font-size: 0.92rem !important;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 52%;
  width: 3.2%;
  height: 21.5%;
  border-radius: 50%;
  backdrop-filter: blur(6px);
  opacity: 1;
  background: hsl(203deg 2.17% 30.22% / 14%);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.carousel-control-next {
  right: 0.7rem;
}

.carousel-indicators {
  transform: translate(-16%, 50%);
}

.carousel-indicators [data-bs-target] {
  background-color: #adb5bd;
}

.card-header-tabs .nav-link.active {
  @extend .bg-primary;
  @extend .text-white;
}

.nav-tabs .nav-link {
  background: #f3f9ffdb;
  color: #7c7d7e;
}

.nav-underline .nav-link.active {
  @extend .text-primary;
  @extend .border-primary;
  @extend .border-bottom;
  @extend .border-2;
}

.nav-underline .nav-link {
  @extend .text-dark;
  @extend .text-opacity-25;
}

.custom-placeholder .placeholder {
  min-height: 0.7rem;
}

.modal-content-transparent .modal-content {
  @extend .border-0;
  @extend .bg-transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  overflow: hidden;
}

.table-history .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: rgb(241 250 255);
  color: var(--bs-table-striped-color);
}

.table-history .card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: rgb(241 250 255);
  border-top: var(--bs-card-border-width) solid rgb(233 241 246);
}

/* Small screens */
@media (max-width: 576px) {
  .pagination {
    flex-wrap: wrap;
    justify-content: center;
  }

  .pagination .page-item {
    margin-bottom: 0.5rem;
  }
}

/* Medium screens */
@media (min-width: 577px) and (max-width: 992px) {
  .pagination {
    justify-content: center;
  }

  .pagination .page-item {
    margin: 0 0.25rem;
  }
}

.color-mix {
  background-color: color-mix(in srgb, #34c9eb 5%, white);
}

a:hover,
a:focus {
  text-decoration: none;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
}

.text-secondary.user-select-all ::selection {
  @extend .text-black;
}



.sticky-headers th,
.sticky-first-column td {
  position: -webkit-sticky;
  position: sticky;
}

.sticky-headers th:first-child {
  left: 0;
  z-index: 2;
}

.sticky-headers th:nth-child(2) {
  z-index: 2;
}

.sticky-first-column td:first-child {
  left: 0;
  z-index: 1;
  background-color: #f5fafe;
}

.sticky-first-column td:nth-child(2) {
  z-index: 1;
  background-color: #f5fafe;
}

.equal-height-form-control {
  height: 40px;
}

.btn-padding {
  --bs-btn-padding-x: 0;
  /* Sets horizontal padding to 0 */
  --bs-btn-padding-y: 0;
  /* Sets vertical padding to 0 */

}

.sticky-first-row  { 
  background-color: #fdfeff;
  z-index: 2;
}
